.search-box {
  display: grid;
  align-items: baseline;
  column-gap: 10px;
  row-gap: 4px;
  width: 100%;
}

.search-box_with-label {
  grid-template-columns: max-content 1fr;
}

.search-box__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.search-box__button {
  padding-left: 4px;
  padding-right: 4px;
}

.search-box__selected-list {
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  border-bottom: none;
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
}

.search-box__selected-item {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.25rem 0.5rem;
  background-color: #d6d6d6;
  border-radius: 999px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
  color: rgb(107, 114, 128);
  width: max-content;
  max-width: 100%;
}

.search-box__selected-item .label {
  text-overflow: ellipsis;
}

.search-box__selected-item button {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  color: rgb(243, 244, 246);
  background-color: #aeaeae;
  font-size: 14px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-box__input {
  border: 1px solid rgb(213, 213, 213);
  width: 100%;
  color: inherit;
  line-height: 20px;
  font-size: 12px;
  padding: 5px 4px;
  outline: none;
}

.search-box__input:focus {
  border-color: rgb(245, 153, 66);
  box-shadow: 0 0 6px rgb(245 153 66 / 30%);
}

.search-box__input_error:focus {
  border-color: #e9322d;
  box-shadow: 0 0 6px #f8b9b7;
}

.search-box__label {
  flex-basis: 100%;
  word-break: break-word;
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  align-items: flex-start;
  text-align: right;
}

.search-box__options {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 15rem;
  margin-top: 0.25rem;
  background-color: #ffffff;
  overflow-y: auto;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
  white-space: break-spaces;
}

.search-box__option {
  color: #000;
  line-height: 1.25rem;
  font-size: 13px;
  padding: 0.25rem 1rem;
  cursor: default;
}

.search-box__option:not([aria-disabled="true"]) {
  cursor: pointer;
}

.search-box__option_active:not([aria-disabled="true"]) {
  color: #fff;
  background-color: rgb(167, 216, 229);
}

@media (max-width: 768px) {
  .search-box {
    grid-template-columns: 1fr;
  }

  .search-box__label {
    text-align: left;
    justify-content: flex-start;
  }
}
