input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #838281 !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  @apply bg-white border border-[#D5D5D5] focus:border-[#F59942] focus:outline-none;
}

.submenu:hover > i {
  @apply block;
}

.submenu:hover > p::before {
  @apply w-0;
}

.scrollbarHide::-webkit-scrollbar {
  @apply hidden;
}

.scrollbarHide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.user-name::-webkit-scrollbar {
  @apply hidden;
}

.user-name {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dashboard-nav::-webkit-scrollbar {
  @apply hidden;
}

.dashboard-nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  @apply h-6 mt-1;
}

.loader-design {
  @apply w-9 h-9 border-4 border-t-transparent mx-auto border-[#d6d6d6] border-solid rounded-full animate-spin;
}

.loader-design-small {
  @apply w-4 h-4 border-2 border-t-transparent mx-auto border-[#d6d6d6] border-solid rounded-full animate-spin;
}

.nestable {
  @apply relative;
}

.nestable .nestable-list:nth-child(2) {
  @apply m-0 ml-[25px] list-none;
}

.nestable > .nestable-list {
  @apply p-0;
}

.nestable-list > .nestable-item > .nestable-list {
  @apply border-l border-dashed border-gray-500 pl-[5px];
}

.nestable-item,
.nestable-item-copy {
  @apply my-[5px];
}

.nestable-item:first-child,
.nestable-item-copy:first-child {
  @apply mt-0;
}

.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
  @apply mt-[5px];
}

.nestable-item {
  @apply relative;
}

.nestable-item.is-dragging .nestable-list {
  @apply pointer-events-none;
}

.nestable-item.is-dragging * {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

.nestable-item.is-dragging:before {
  @apply content-[''] absolute inset-0 bg-[#eeeefe] border border-dashed border-[#8485b9];
}

.nestable-item-icon {
  @apply mr-[5px] cursor-pointer;
}

.nestable-drag-layer {
  @apply fixed top-0 left-0 z-[100] pointer-events-none;
}

.nestable-drag-layer > .nestable-list {
  @apply absolute top-0 left-0 p-0;
}

.table-wrapper {
  @apply bg-white overflow-hidden;
}

.table-wrapper table thead,
.table-wrapper table tbody,
.table-wrapper table tr {
  @apply contents;
}

.table-wrapper table th,
.table-wrapper table td {
  @apply text-left min-w-[100px];
}

.table-wrapper table th span,
.table-wrapper table td span {
  @apply whitespace-nowrap text-ellipsis overflow-hidden block;
}

.progress-bar {
  @apply box-content h-5 relative rounded-md w-full;
}

.progress-bar > span.active:after {
  @layer content-[''] absolute inset-0 z-10 [background-size:40px_40px] [animation:move_2s_linear_infinite] [background-image:linear-gradient(45deg,rgba(255,255,255,0.2)_25%, transparent_25%, transparent_50%,rgba(255,255,255,0.2)_50%,rgba(255,255,255,0.2)_75%, transparent_75%, transparent)];
}

@keyframes move {
  0% {
    background-position: 40px 0;
  }
  100% {
    background-position: 0 0;
  }
}

.notification-item {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin-bottom: 20px;
  animation: SlideLeft 0.4s;
  animation-fill-mode: forwards;
  width: 300px;
}

@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
}

.notification-item.exit {
  animation: SlideRight 0.4s;
  animation-fill-mode: forwards;
}

.notification-item p {
  margin: 0;
  padding: 10px;
}

.font-thirteen-px {
  @apply text-[13px] text-[#393939] leading-5 font-bold whitespace-nowrap;
}

.aclEditableFalse {
  @apply font-bold text-[13px] leading-5 text-[#393939];
}

::selection {
  @apply bg-red-500 text-white;
}

@media screen and (max-height: 850px) {
  .sub-menu-sidebar {
    @apply max-h-[300px] bg-red-500;
  }
}

.filter-header {
  background: #848484;
  border-color: #999;
  min-height: 31px;
  color: #ffffff;
  display: grid;
  grid-template-columns: 1fr max-content max-content;
}

.filter-header > h4 {
  line-height: 30px;
  font-size: 18px;
}
