.checker-wrapper {
  display: grid;
  align-items: center;
  column-gap: 10px;
  row-gap: 4px;
  width: 100%;
  grid-template-columns: 1fr;
}

.checker-wrapper_with-label {
  grid-template-columns: max-content 1fr;
}

.checker-wrapper__input-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  align-items: flex-start;
}

.checker-wrapper__label {
  flex-basis: 100%;
  width: 100%;
  word-break: break-word;
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  align-items: flex-start;
  text-align: right;
}

.checker-wrapper__input {
  width: 13px;
  height: 13px;
  background-color: #fafafa;
  border: 1px solid #ccc;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.checker-wrapper__input:hover:not(:disabled) {
  border-color: #f9a021;
}

.checker-wrapper__input:disabled {
  opacity: 0.5;
  cursor: default;
}

.checker-wrapper__input_checked {
  border: 1px solid #f9a021;
  background-color: #f9a021;
  color: #fff;
}

@media (max-width: 768px) {
  .checker-wrapper_with-label {
    grid-template-columns: 1fr;
  }

  .checker-wrapper__label {
    text-align: left;
    justify-content: flex-start;
    width: 100% !important;
  }
}
