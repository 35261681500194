.history-table {
}

.history-table__title {
  background-color: #307ecc;
  color: #fff;
  font-size: 14px;
  line-height: 38px;
  padding-left: 12px;
  margin-bottom: 1px;
}
