.new-quote__info {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.new-quote-info__header {
  margin-top: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.new-quote-info__selects {
  padding: 19px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
}

.new-quote-info__select-wrapper .select {
  margin-top: 0 !important;
}

.new-quote-info__select-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

@media (max-width: 768px) {
  .new-quote-info__select-wrapper {
    flex-flow: column;
    align-items: flex-start;
  }
}
