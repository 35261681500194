.edit-quote__selects {
  padding: 19px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
}

.edit-quote__column-content {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.edit-quote__centered-items {
  align-items: center;
}

.edit-quote__misc-card {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
}

.edit-quote__misc-card-header {
  background-color: #307ecc;
  font-size: 16px;
  line-height: 1.25rem;
  color: #fff;
  padding: 5px 10px;
}

.edit-quote__misc-card-content {
  padding: 0.75rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.edit-quote__misc-card-content-item {
  font-size: 13px;
  line-height: 1.25rem;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: baseline;
  gap: 8px;
}

.edit-quote__misc-card-content-item > .value {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: baseline;
  justify-content: space-between;
}

.edit-quote__misc-card-content-item > .actions {
  display: flex;
  align-items: flex-start;
}

.edit-quote__misc-card-content-item > .comment > .dsc {
  color: #99171d;
  font-weight: 700;
}

.edit-quote__misc-card-content > .selects {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 4px;
}

.edit-quote__file-input {
  font-size: 13px;
  line-height: 1.25rem;
  color: #888;
  border: 1px solid #cccccc;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  box-shadow: 0 0 1px 4px #f0f0f0;
}

.edit-quote__files-wrapper {
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.edit-quote__files-wrapper_dragging {
  border-color: rgb(245, 153, 66);
  box-shadow: 0 0 1px 4px rgb(245 153 66 / 30%);
}


.edit-quote__file-input:hover {
  border-color: rgb(245, 153, 66);
  box-shadow: 0 0 1px 4px rgb(245 153 66 / 30%);
}

.edit-quote__file-list {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  line-height: 1.25rem;
  color: #393939;
}

.edit-quote__file-list-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr) max-content;
  gap: 8px;
  padding: 8px;
  border-top: 1px solid #cccccc;
}
