.state-box {
  display: grid;
  align-items: baseline;
  column-gap: 10px;
  row-gap: 4px;
  width: 100%;
  grid-template-columns: max-content 1fr;
}

.state-box__wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  align-items: flex-start;
}

.state-box__input {
  width: 100%;
  color: inherit;
  line-height: 20px;
  font-size: 12px;
  padding: 5px 4px;
  outline: none;
}

.state-box__input:not(:read-only):not(:disabled) {
  border: 1px solid rgb(213, 213, 213);
}

.state-box__input:read-only, .state-box__input:disabled {
  background: transparent;
  font-weight: 500;
}

.state-box__input:focus:not(:read-only):not(:disabled) {
  border-color: rgb(245, 153, 66);
  box-shadow: 0 0 6px rgb(245 153 66 / 30%);
}

.state-box__input_error:focus:not(:read-only):not(:disabled) {
  border-color: #e9322d;
  box-shadow: 0 0 6px #f8b9b7;
}

.state-box__label {
  flex-basis: 100%;
  word-break: break-word;
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  align-items: flex-start;
  text-align: right;
}

.state-box__options {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: 15rem;
  margin-top: 0.25rem;
  background-color: #ffffff;
  overflow-y: auto;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.state-box__option {
  color: #000;
  line-height: 1.25rem;
  font-size: 13px;
  padding: 0.25rem 1rem;
  cursor: default;
}

.state-box__option:not([aria-disabled="true"]) {
  cursor: pointer;
}

.state-box__option:not([aria-disabled="true"]):hover {
  color: #fff;
  background-color: rgb(167, 216, 229);
}

@media (max-width: 768px) {
  .state-box {
    grid-template-columns: 1fr;
  }

  .state-box__label {
    text-align: left;
    justify-content: flex-start;
  }
}
