@font-face {
  font-family: "fontello";
  src: url("../font/fontello.eot?59740159");
  src:
    url("../font/fontello.eot?59740159#iefix") format("embedded-opentype"),
    url("../font/fontello.woff2?59740159") format("woff2"),
    url("../font/fontello.woff?59740159") format("woff"),
    url("../font/fontello.ttf?59740159") format("truetype"),
    url("../font/fontello.svg?59740159#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?59740159#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-leaf:before {
  content: "\e800";
}

/* '' */
.icon-user:before {
  content: "\e801";
}

/* '' */
.icon-key:before {
  content: "\e802";
}

/* '' */
.icon-lock:before {
  content: "\e803";
}

/* '' */
.icon-users:before {
  content: "\e804";
}

/* '' */
.icon-bookmark:before {
  content: "\e805";
}

/* '' */
.icon-list-alt:before {
  content: "\e806";
}

/* '' */
.icon-chart-bar:before {
  content: "\e807";
}

/* '' */
.icon-cog:before {
  content: "\e808";
}

/* '' */
.icon-calendar:before {
  content: "\e809";
}

/* '' */
.icon-power:before {
  content: "\e80a";
}

/* '' */
.icon-home:before {
  content: "\e80b";
}

/* '' */
.icon-up-open:before {
  content: "\e80c";
}

/* '' */
.icon-minus:before {
  content: "\e80d";
}

/* '' */
.icon-floppy:before {
  content: "\e80e";
}

/* '' */
.icon-th-list:before {
  content: "\e80f";
}

/* '' */
.icon-plus:before {
  content: "\e810";
}

/* '' */
.icon-export:before {
  content: "\e811";
}

/* '' */
.icon-info-circled:before {
  content: "\e812";
}

/* '' */
.icon-pencil:before {
  content: "\e813";
}

/* '' */
.icon-cancel:before {
  content: "\e814";
}

/* '' */
.icon-ok:before {
  content: "\e815";
}

/* '' */
.icon-down-open:before {
  content: "\e816";
}

/* '' */
.icon-arrows-cw:before {
  content: "\e817";
}

/* '' */
.icon-trash-empty:before {
  content: "\e818";
}

/* '' */
.icon-truck:before {
  content: "\e819";
}

/* '' */
.icon-credit-card:before {
  content: "\e81a";
}

/* '' */
.icon-list:before {
  content: "\e81b";
}

/* '' */
.icon-cancel-circled:before {
  content: "\e81c";
}

/* '' */
.icon-edit:before {
  content: "\e81d";
}

/* '' */
.icon-search:before {
  content: "\e81e";
}

/* '' */
.icon-comment:before {
  content: "\e81f";
}

/* '' */
.icon-picture:before {
  content: "\e820";
}

/* '' */
.icon-mail:before {
  content: "\e821";
}

/* '' */
.icon-doc:before {
  content: "\e822";
}

/* '' */
.icon-upload:before {
  content: "\e823";
}

/* '' */
.icon-down-circled2:before {
  content: "\e824";
}

/* '' */
.icon-off:before {
  content: "\e825";
}

/* '' */
.icon-off-1:before {
  content: "\e826";
}

/* '' */
.icon-spin4:before {
  content: "\e834";
}

/* '' */
.icon-bookmark-empty:before {
  content: "\f097";
}

/* '' */
.icon-menu:before {
  content: "\f0c9";
}

/* '' */
.icon-sort:before {
  content: "\f0dc";
}

/* '' */
.icon-sort-down:before {
  content: "\f0dd";
}

/* '' */
.icon-gauge:before {
  content: "\f0e4";
}

/* '' */
.icon-exchange:before {
  content: "\f0ec";
}

/* '' */
.icon-coffee:before {
  content: "\f0f4";
}

/* '' */
.icon-angle-double-left:before {
  content: "\f100";
}

/* '' */
.icon-angle-double-right:before {
  content: "\f101";
}

/* '' */
.icon-angle-left:before {
  content: "\f104";
}

/* '' */
.icon-angle-right:before {
  content: "\f105";
}

/* '' */
.icon-angle-up:before {
  content: "\f106";
}

/* '' */
.icon-angle-down:before {
  content: "\f107";
}

/* '' */
.icon-lock-open-alt:before {
  content: "\f13e";
}

/* '' */
