.form-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  height: max-content;
}

.form-card__header {
  border-bottom: 1px solid #ddd;
  background: #f7f7f7;
  padding: 4px 8px;

  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  justify-content: space-between;
  align-items: baseline;
}

.form-card__header .label {
  line-height: 31px;
  font-size: 16px;
  color: #669fc7;
}

.form-card__header .control {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.form-card__content-list {
  padding: 16px 28px;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
}

.form-card__content {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
}

.form-card__content_semi {
  width: calc((100% - 16px) / 2);
}

.form-card__exp-date-select {
  width: calc(50% - 4px);
}

@media (max-width: 768px) {
  .form-card__content-list {
    flex-flow: column;
  }

  .form-card__content_semi {
    width: 100%;
  }
}
