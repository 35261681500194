.filter-heading {
  background: #848484;
  border-color: #999;
  min-height: 31px;
  color: #ffffff;
  padding: 1.5px 10px;
  display: flex;
  flex-flow: row wrap;
  gap: 14px;
}

.filter-heading__management-state-box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-grow: 1;
  flex-basis: 350px;
}

.filter-heading__title {
  line-height: 30px;
  font-size: 18px;
}

.filter-heading__toggle-opened-state-btn {
  border-left: 1px solid;
  padding: 0 10px;
  font-size: 14px;
}

.filter-heading__toggle-opened-state-btn > i::before {
  transition: transform 250ms ease-in-out;
}

.filter-heading__toggle-opened-state-btn_rotate-0 > i::before {
  transform: rotate(0);
}

.filter-heading__toggle-opened-state-btn_rotate-180 > i::before {
  transform: rotate(180deg);
}

.filter-heading .filter-heading__management-filter {
  display: flex;
  flex-flow: row wrap;
  flex-grow: 0;
  gap: 14px;
  align-items: center;
}

.filter-heading__management-filter-input {
  width: 100%;
  max-width: 109px;
}

.filter-heading__management-filter-actions {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  align-items: center;
  padding-right: 3.375rem;
}

.filter-form-button {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  padding: 8px 20px;
}

.filter-form-button button {
  color: #fff;
  padding: 0 8px;
}

.filter-form-content__form {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.filter-form-content__form:not(:first-child) {
  margin-left: 2.127659574468085%;
}

.filter-form-content__input-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  align-items: flex-start;
}

.filter-form-content__input-wrapper_default {
  display: grid;
  grid-template-columns: 100px 1fr;
}

.filter-form-content__input-wrapper_date {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 33px;
}

.filter-form-content__input-wrapper .label {
  padding-top: 5px;
  text-align: right;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}

.filter-form-content__input-wrapper .input > .row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.filter-form-content__input-wrapper .input > .column {
  display: flex;
  flex-flow: column;
}

.filter-form-content__input-wrapper .input > .row,
.filter-form-content__input-wrapper .input > .column {
  gap: 4px;
}

.filter-container__content {
  border: 1px solid #ccc;
  border-top: none;
  background-color: #fff;
  padding: 12px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  height: max-content;
}

.filter-form-content__field-wrapper {
  width: 100%;
}

@media (max-width: 768px) {
  .filter-heading__management-filter-input {
    max-width: unset;
  }

  .filter-heading__management-filter-actions {
    padding-right: 0;
  }

  .filter-form-content__form:not(:first-child) {
    margin-left: 0;
  }
}
