.form-template {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  height: max-content;
}

.form-template__label {
  color: #669fc7;
  border-bottom: 1px solid #ddd;
  background: #f7f7f7;
  padding-left: 10px;
  line-height: 31px;
}

.form-template__content {
  padding: 16px 28px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.form-template__field {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.form-template__field .error:focus {
  border-color: #e9322d;
  box-shadow: 0 0 6px #f8b9b7;
}

.form-template__field .label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  max-width: 135px;
  text-align: right;
}

.form-template__select {
  background-color: #dcdcdc;
  border: 1px solid #cccccc;
}

.form-template__checkbox {
  width: 13px;
  height: 13px;
  background-color: #fafafa;
  border: 1px solid #ccc;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.form-template__checkbox:hover {
  border-color: #f9a021;
}

.form-template__checkbox_checked {
  border: 1px solid red;
  background-color: #f9a021;
  border-color: #f9a021;
  color: #fff;
}

.form-template__exp-date-wrapper {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  gap: 4px;
}
