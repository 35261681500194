.yes-or-no-box {
  display: grid;
  align-items: baseline;
  column-gap: 10px;
  row-gap: 4px;
  width: 100%;
  grid-template-columns: 1fr;
}

.yes-or-no-box_with-label {
  grid-template-columns: max-content 1fr;
}

.yes-or-no-box__label {
  flex-basis: 100%;
  word-break: break-word;
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  align-items: flex-start;
  text-align: right;
}

@media (max-width: 768px) {
  .yes-or-no-box_with-label {
    grid-template-columns: 1fr;
  }

  .yes-or-no-box__label {
    text-align: left;
    justify-content: flex-start;
  }
}
