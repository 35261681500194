.input-box {
  display: grid;
  align-items: baseline;
  column-gap: 10px;
  row-gap: 4px;
  width: 100%;
  grid-template-columns: 1fr;
}

.input-box_with-label {
  grid-template-columns: max-content 1fr;
}

.input-box__input-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  align-items: flex-start;
}

.input-box__input {
  color: inherit;
  line-height: 20px;
  font-size: 12px;
  padding: 4px;
  outline: none;
  width: 100%;
  flex-basis: 100%;
  flex-shrink: 1;
}

.input-box__input:not(:read-only):not(:disabled) {
  border: 1px solid rgb(213, 213, 213);
}

.input-box__input:read-only, .input-box__input:disabled {
  background: transparent;
  font-weight: 500;
}

.input-box__input::placeholder {
  color: #aaaaaa;
}

.input-box__input:focus:not(:disabled):not(:read-only) {
  border-color: rgb(245, 153, 66);
  box-shadow: 0 0 6px rgb(245 153 66 / 30%);
}

.input-box__input_error:focus:not(:disabled):not(:read-only) {
  border-color: #e9322d;
  box-shadow: 0 0 6px #f8b9b7;
}

.input-box__label {
  flex-basis: 100%;
  width: 100%;
  word-break: break-word;
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  align-items: flex-start;
  text-align: right;
}

@media (max-width: 768px) {
  .input-box_with-label {
    grid-template-columns: 1fr;
  }

  .input-box__label {
    text-align: left;
    justify-content: flex-start;
    width: 100% !important;
  }
}
