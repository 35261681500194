.admin-unitig-clients {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 16px;
}

.admin-unitig-clients__content {
	display: flex;
	gap: 12px;
	flex-direction: column;
	max-width: 300px;
}