.calendar-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.calendar-box__label {
  flex-basis: 100%;
  word-break: break-word;
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  align-items: flex-start;
  text-align: right;
}

.calendar-box__input-list {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.calendar-box__input-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.calendar-box__input-wrapper .icon-calendar {
  padding: 0 7px;
  background-color: rgb(238, 238, 238);
  border: 1px solid rgb(213, 213, 213);
  border-right-width: 0;
  cursor: pointer;
}

.calendar-box__input {
  width: 100%;
  border: 1px solid rgb(213, 213, 213);
  color: inherit;
  line-height: 20px;
  font-size: 12px;
  padding: 5px 4px;
  outline: none;
}

.calendar-box__input:focus {
  border-color: rgb(245, 153, 66);
  box-shadow: 0 0 6px rgb(245 153 66 / 30%);
}

.calendar-box__input_error:focus {
  border-color: #e9322d;
  box-shadow: 0 0 6px #f8b9b7;
}

@media (max-width: 768px) {
  .calendar-box {
    flex-direction: column;
    align-items: flex-start;
  }
}
