.multiple-select {
  background: #fff;
  border: 1px solid #cccccc;
  line-height: 30px;
  padding: 4px 6px;
  font-size: 14px;
  color: #555555;
  width: 100%;
}

.multiple-select option {
  padding: 3px 4px;
  cursor: pointer;
}

.multiple-select option:disabled {
  cursor: default;
}

.multiple-select option:hover:not(:disabled) {
  background-color: #e5e9ee;
  color: #111;
}
