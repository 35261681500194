.select-box {
  display: grid;
  align-items: baseline;
  column-gap: 10px;
  row-gap: 4px;
  width: 100%;
  grid-template-columns: 1fr;
}

.select-box_with-label {
  grid-template-columns: max-content 1fr;
}

.select-box__select-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
  align-items: flex-start;
}

.select-box__select_white:not(:disabled) {
  background-color: #ffffff;
}

.select-box__select_gray:not(:disabled) {
  background-color: #dcdcdc;
}

.select-box__select:not(:disabled) {
  border: 1px solid #cccccc;
}

.select-box__select:disabled {
  opacity: 1;
  font-weight: 500;
}

.select-box__select {
  color: inherit;
  flex-basis: 100%;
  line-height: 20px;
  font-size: 12px;
  padding: 5px 4px;
  outline: none;
  width: 100%;
}

.select-box__select:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0px;
  text-overflow: '';
}

.select-box__select_multiple {
  background: #fff !important;
  border: 1px solid #cccccc;
  line-height: 30px;
  padding: 4px 6px;
  font-size: 14px;
  color: #555555;
  width: 100%;
  max-width: 150px;
}

.select-box__select_multiple option {
  padding: 3px 4px;
  cursor: pointer;
}

.select-box__select_multiple option:hover:not(:disabled) {
  background-color: #e5e9ee;
  color: #111;
}

.select-box__select:focus {
  border-color: rgb(245, 153, 66);
  box-shadow: 0 0 6px rgb(245 153 66 / 30%);
}

.select-box__select_error:focus {
  border-color: #e9322d;
  box-shadow: 0 0 6px #f8b9b7;
}

.select-box__label {
  flex-basis: 100%;
  word-break: break-word;
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  align-items: flex-start;
  text-align: right;
}

@media (max-width: 768px) {
  .select-box_with-label {
    grid-template-columns: 1fr;
  }

  .select-box__label {
    text-align: left;
    justify-content: flex-start;
    width: 100% !important;
  }
}
