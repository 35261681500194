.select-combobox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: max-content;
}

.select-combobox__label {
  flex-basis: 100%;
  word-break: break-word;
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  align-items: flex-start;
  text-align: right;
}

.select-combobox__wrapper {
  position: relative;
  width: 100%;
}

.select-combobox__options {
  position: absolute;
  top: 100%;
  width: 100%;
  min-width: 100px;
  right: 0;
  max-height: 15rem;
  margin-top: 0.25rem;
  background-color: #ffffff;
  overflow-y: auto;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.select-combobox__option {
  color: #000;
  line-height: 1.25rem;
  font-size: 12px;
  padding: 2px 0.5rem;
  cursor: default;
}

.select-combobox__option:not([aria-disabled="true"]) {
  cursor: pointer;
}

.select-combobox__option:not([aria-disabled="true"]):hover {
  color: #fff;
  background-color: rgb(167, 216, 229);
}

.select-combobox__button {
  width: 100%;
}

.select-combobox__button .btn-group {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.select-combobox__button .btn-group .btn {
  text-align: left;
  white-space: nowrap;
}

.select-combobox__button .label {
}

.select-combobox__button i {
}
