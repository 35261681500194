* {
  word-break: break-word;
}

html {
  color: #494949;
}

.btn {
  display: inline-block;
  padding: 0 12px 1px;
  line-height: 32px;
  color: #fff !important;
  font-size: 14px;
  background-image: none !important;
  background-color: #abbac3 !important;
  border: 5px solid #abbac3;
  border-radius: 0 !important;
  box-shadow: none !important;
  -moz-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  vertical-align: middle;
  margin: 0;
  position: relative;
  white-space: pre;
}

.btn:disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.no-opacity {
  position: relative;
}

.no-opacity:after {
  content: '';
  position: absolute;
  top: 0;
  background: white;
  bottom: 0;
  left: 0;
  right: 0;
}
.btn:hover {
  background-color: #8b9aa3 !important;
}

.btn.active {
  background-color: #91a0a5 !important;
  border-color: #8796a0;
}

.btn.active::after {
  display: inline-block;
  content: "";
  position: absolute;
  border-bottom: 1px solid #efe5b5;
}

.btn:disabled {
  cursor: default;
}

.btn-group:not(.btn) {
  display: flex;
  flex-flow: row nowrap;
  gap: 1px;
}

.btn-group__with-large-gap:not(.btn) {
  gap: 8px;
}

.btn-mini {
  padding: 0 4px;
  line-height: 22px;
  border-width: 2px;
  font-size: 12px;
  line-height: 23px;
}

.btn-group.btn-mini,
.btn-group .btn-mini {
  font-size: 10.5px;
}

.btn-mini::after {
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-bottom-width: 1px;
}

.btn-small {
  padding: 0 8px;
  line-height: 24px;
  border-width: 4px;
  font-size: 13px;
}

.btn-small:has(.icon-medium) {
  font-size: 16px !important;
}

.btn-group.btn-small,
.btn-group .btn-small {
  border-width: 2px !important;
  font-size: 11.9px;
  padding: 0 4px;
}

.btn-small::after {
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-bottom-width: 1px;
}

.btn.dropdown-toggle {
  padding: 0 3px !important;
}

.btn-success {
  background-color: #87b87f !important;
  border-color: #87b87f;
}

.btn-blue {
  background-color: #6fb3e0 !important;
  border-color: #6fb3e0;
}

.btn-blue:hover {
  background-color: #3193d4 !important;
}

.btn-success:hover {
  background-color: #629b58 !important;
}

.btn-warning {
  background-color: #d15b47 !important;
  border-color: #d15b47;
}

.btn-warning:hover {
  background-color: #b74635 !important;
}

.btn-yellow {
  background-color: #fee188 !important;
  border-color: #fee188;
  color: #996633 !important;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4) !important;
}

.btn-yellow:hover {
  background-color: #f7d05b !important;
}

.btn-transparent {
  color: #0088cc !important;
  background-color: transparent !important;
  border: none !important;
}

.btn-transparent:hover {
  color: #005580 !important;
  background-color: transparent !important;
}

a,
.link {
  color: #0088cc;
  text-decoration: none;
  cursor: pointer;
}

a:hover:not(:disabled),
.link:hover:not(:disabled) {
  color: #005580;
  text-decoration: underline;
}

a:disabled,
.link:disabled {
  color: #aecea9;
}

.row-fluid .box-max {
  width: max-content;
}

.row-fluid .box-full {
  width: 100%;
}

.row-fluid .box-1 {
  width: 7.80141843971631%;
}

.row-fluid .box-2 {
  width: 15.60283687943262%;
}

.row-fluid .box-3 {
  width: 23.404255319148934%;
}

.row-fluid .box-4 {
  width: 31.623931623931625%;
}

.row-fluid .box-5 {
  width: 40.17094017094017%;
}

.row-fluid .box-6 {
  width: 48.717948717948715%;
}

.row-fluid .box-7 {
  width: 57.26495726495726%;
}

.row-fluid .box-8 {
  width: 65.81196581196582%;
}

.row-fluid {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  align-items: flex-start;
}

.row-fluid > [class*="box-"]:not(:first-child) {
  margin-left: 2.127659574468085%;
}

.red {
  color: #dd5a43 !important;
}

input,
button,
select,
textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

select:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(245, 153, 66, 0.3);
  border-color: #f59942;
}

.layout-content {
  padding: 8px 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.layout-content__header {
  margin-bottom: 35px;
}

@media (max-width: 768px) {
  .row-fluid [class*="box-"] {
    width: 100%;
  }

  .row-fluid [class*="box-"]:not(:first-child) {
    width: 100%;
    margin-left: 0;
    margin-top: 7px;
  }

  .layout-content {
    padding: 8px 11px 24px;
  }
}

.flex {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center;
}

.gap-2 {
  gap: .5em !important;
}
