.table__wrapper {
  width: 100%;
}

.table__content-wrapper {
  width: 100%;
  overflow-x: auto;
  height: max-content;
}

.table {
  width: 100%;
}

.table_collapse {
  border-collapse: collapse;
}

.table,
.table th,
.table td {
  border: 1px solid #dddddd;
}

.table__thead_gray {
  background: linear-gradient(to bottom, #f8f8f8 0%, #ececec 100%);
  color: inherit;
}

.table__thead_blue {
  background: rgb(0, 120, 174);
  color: #ffffff;
}

.table__info {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  word-break: break-word;
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.table__info-title {
  font-weight: 500;
}

.table th,
.table td {
  padding: 4px 5px;
  line-height: 20px;
  text-align: left;
  font-size: 13px;
  height: 28px;
  vertical-align: middle;
}

.table tr {
  transition: all 0.15s ease-in-out;
}

.table tr.selected td {
  font-weight: 700;
}

.table td i.icon-medium {
  font-size: 16px;
}

.table tr.active td {
  background-color: #f8da4e;
}

.table tr.inactive:hover td {
  background-color: #79c9ec;
}

.table th {
  position: relative;
  padding-right: 20px;
  cursor: pointer;
  white-space: nowrap;
}

.table th.small {
  width: 0;
}

.table__sort-icon {
  font-size: 13px;
  color: #555;
  line-height: 20px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 4px;
  right: 5px;
}

.table__page-size {
  display: flex;
  flex-flow: row wrap;
  gap: 1px;
  justify-content: flex-start;
}

.table__pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 12px;
}

.table__pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.table__pagination button {
  color: #2283c5;
  background-color: #fafafa;
  border-color: #e0e8eb;
  padding: 4px 12px;
  line-height: 20px;
  border: 1px solid #dddddd;
  cursor: pointer;
  height: 30px;
}

.table__pagination .double-arrow svg {
  width: 6px;
}

.table__pagination .single-arrow svg {
  width: 3px;
}

.table__pagination button.active {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #6faed9;
  border-color: #6faed9;
}

.table__pagination button:disabled {
  background-color: #f9f9f9;
  border-color: #d9d9d9;
  color: #999999;
}

.table__pagination button.active,
.table__pagination button:disabled {
  cursor: default;
}

.table__pagination button:hover:not(:disabled):not(.active) {
  background-color: #eaeff2;
}

.table_info-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .table__pagination-wrapper {
    justify-content: center;
    margin-top: 30px;
  }
}
