.admin-dashboard {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.admin-dashboard__title {
  font-weight: 700;
  line-height: 1.6;
  color: #333;
  font-size: 38px;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.75rem;
  margin-bottom: 16px;
}

.admin-dashboard__subtitle {
  line-height: 1.25rem;
  font-weight: 700;
  font-size: 18px;
}

.admin-dashboard__breadcrumbs {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 8px 16px;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.42;
  color: rgba(0, 0, 0, 0.2);
}

.admin-dashboard__additional-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
}

.admin-dashboard__filter-wrapper {
  background-color: rgb(245, 245, 245);
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 1.25rem;
  border-radius: 4px;
}

.admin-dashboard__filter-search-refresh-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.admin-dashboard__filter-search {
  max-width: 170px !important;
}

.admin-dashboard__filter-field-list {
  display: flex;
  padding-bottom: 1.25rem;
  flex-direction: column;
  gap: 8px;
}

.admin-dashboard__filter-field-item {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
}

.admin-dashboard__filter-field-item .select-item {
  width: 100%;
  max-width: 200px;
}

.admin-dashboard__filter-field-item .select-item-wrapper-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.admin-dashboard__filter-field-list hr {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #ffffff;
}

.admin-dashboard__delete-items-list {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1rem;
  gap: 16px;
}

.admin-dashboard__delete-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.admin-dashboard__delete-item > .type {
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  padding: 4px 2px;
  background-color: #999;
  border-radius: 4px;
  color: #fff;
}

.admin-dashboard__delete-item > .label {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.admin-dashboard__action-item-btn-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.admin-dashboard__info-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.admin-dashboard__info-list-item {
  width: 100%;
}

.admin-dashboard__info-list-item > .info {
  width: 100%;
  color: #333333;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: 1.25rem;

  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 1.25rem;
}

.admin-dashboard__info-list-item > .label {
  background-color: #3a87ad;
  color: #fff;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  width: max-content;
  max-width: 100%;
}

.admin-dasboard__modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-dashboard__modal-wrapper {
  background-color: #fff;
  z-index: 50;
  max-width: 600px;
  width: 100%;
  max-height: 100%;
  border-radius: 4px;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
}

.admin-dasboard__modal-content {
  width: 100%;
  max-height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
}

.admin-dasboard__modal-actions {
  padding: 16px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.admin-dasboard__modal-header {
  padding: 16px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.admin-dashboard__progress-bar {
  width: 100%;
  min-width: 500px;
  background-color: rgb(246, 246, 246);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  height: 20px;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #fff;
}

@media (max-width: 768px) {
  .admin-dashboard__title {
    font-size: 25px;
  }

  .admin-dashboard__filter-search-refresh-block {
    justify-content: center;
  }

  .admin-dashboard__filter-field-item {
    flex-direction: column;
    align-items: center;
  }
}
