@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

*,
*::before,
*::after {
  @apply m-0 p-0 box-border;
}

@layer base {
  .default {
    --header-background: 57, 57, 57;
    --sidebar-main-menu-background: 224, 224, 224;
    --sidebar-sub-menu-background: 255, 255, 255;
    --sidebar-collapse-background: 208, 208, 208;
    --sidebar-collapse-border: 255, 255, 255;
    --sidebar-bottom-background: 214, 214, 214;
    --sidebar-main-heading-text-color: 90, 90, 90;
    --sidebar-main-heading-text-color-active: 25, 99, 170;
    --sidebar-main-heading-text-color-hover: 25, 99, 170;
    --sidebar-sub-menu-heading-text-color-hover: 75, 136, 183;
    --sidebar-main-heading-hover: 245, 245, 245;
    --sidebar-sub-menu-text-color: 97, 97, 97;
    --sidebar-main-header-border: 255, 255, 255;
    --sidebar-sub-menu-header-border: 228, 228, 228;
    --sidebar-collapse-border: 224, 224, 224;
    --sidebar-arrow-background: 255, 255, 255;
    --filter-select-background: 171, 186, 195;
    --filter-select-border: 171, 186, 195;
    --filter-select-hover: 139, 154, 163;
    --filter-yer-or-no-background-active: 145, 160, 165;
    --filter-yes-or-no-background-inactive: 171, 186, 195;
    --filter-yes-or-no-border-active: 7, 19, 36;
    --filter-yes-or-no-border-inactive: 41, 99, 195;
    --filter-yes-or-no-hover: 139, 154, 163;
  }

  .blue {
    --header-background: 67, 142, 185;
    --sidebar-main-menu-background: 249, 249, 249;
    --sidebar-sub-menu-background: 255, 255, 255;
    --sidebar-collapse-background: 243, 243, 243;
    --sidebar-collapse-border: 225, 225, 225;
    --sidebar-bottom-background: 242, 242, 242;
    --sidebar-main-heading-text-color: 88, 88, 88;
    --sidebar-main-heading-text-color-active: 25, 99, 170;
    --sidebar-main-heading-text-color-hover: 25, 99, 170;
    --sidebar-sub-menu-heading-text-color-hover: 75, 136, 183;
    --sidebar-main-heading-hover: 240, 248, 255;
    --sidebar-sub-menu-text-color: 97, 97, 97;
    --sidebar-main-header-border: 229, 229, 229;
    --sidebar-sub-menu-header-border: 228, 228, 228;
    --sidebar-collapse-border: 224, 224, 224;
    --sidebar-arrow-background: 255, 255, 255;
    --filter-select-background: 171, 186, 195;
    --filter-select-border: 171, 186, 195;
    --filter-select-hover: 139, 154, 163;
    --filter-yer-or-no-background-active: 145, 160, 165;
    --filter-yes-or-no-background-inactive: 171, 186, 195;
    --filter-yes-or-no-border-active: 7, 19, 36;
    --filter-yes-or-no-border-inactive: 41, 99, 195;
    --filter-yes-or-no-hover: 139, 154, 163;
  }

  .black {
    --header-background: 44, 106, 160;
    --sidebar-main-menu-background: 34, 42, 45;
    --sidebar-sub-menu-background: 58, 67, 68;
    --sidebar-collapse-background: 20, 26, 27;
    --sidebar-collapse-border: 225, 225, 225;
    --sidebar-bottom-background: 34, 42, 45;
    --sidebar-main-heading-text-color: 177, 186, 193;
    --sidebar-main-heading-text-color-active: 133, 192, 236;
    --sidebar-main-heading-text-color-hover: 225, 234, 241;
    --sidebar-sub-menu-heading-text-color-hover: 138, 180, 222;
    --sidebar-main-heading-hover: 65, 75, 81;
    --sidebar-sub-menu-text-color: 209, 218, 225;
    --sidebar-main-header-border: 63, 78, 84;
    --sidebar-sub-menu-header-border: 90, 99, 100;
    --sidebar-collapse-border: 63, 78, 84;
    --sidebar-arrow-background: 34, 42, 45;
    --filter-select-background: 44, 106, 160;
    --filter-select-border: 41, 99, 149;
    --filter-select-hover: 44, 106, 160;
    --filter-yer-or-no-background-active: 14, 28, 61;
    --filter-yes-or-no-background-inactive: 44, 106, 160;
    --filter-yes-or-no-border-active: 7, 19, 36;
    --filter-yes-or-no-border-inactive: 41, 99, 195;
    --filter-yes-or-no-hover: 44, 106, 160;
  }

  .pink {
    --header-background: 198, 72, 126;
    --sidebar-main-menu-background: 57, 57, 57;
    --sidebar-sub-menu-background: 48, 48, 48;
    --sidebar-collapse-background: 44, 44, 44;
    --sidebar-collapse-border: 225, 225, 225;
    --sidebar-bottom-background: 80, 80, 80;
    --sidebar-main-heading-text-color: 221, 221, 221;
    --sidebar-main-heading-text-color-active: 0, 0, 0, 0.5;
    --sidebar-main-heading-text-color-hover: 0, 0, 0;
    --sidebar-main-heading-icon-color-hover: 255, 255, 255;
    --sidebar-sub-menu-heading-text-color-hover: 255, 255, 255;
    --sidebar-main-heading-hover: 255, 171, 0;
    --sidebar-sub-menu-text-color: 204, 204, 204;
    --sidebar-main-header-border: 255, 255, 255;
    --sidebar-main-header-border: 68, 68, 68;
    --sidebar-sub-menu-header-border: 85, 85, 85;
    --sidebar-collapse-border: 119, 119, 119;
    --sidebar-arrow-background: 51, 51, 51;
    --filter-select-background: 171, 186, 195;
    --filter-select-border: 171, 186, 195;
    --filter-select-hover: 139, 154, 163;
    --filter-yer-or-no-background-active: 145, 160, 165;
    --filter-yes-or-no-background-inactive: 171, 186, 195;
    --filter-yes-or-no-border-active: 7, 19, 36;
    --filter-yes-or-no-border-inactive: 41, 99, 195;
    --filter-yes-or-no-hover: 139, 154, 163;
  }
}
