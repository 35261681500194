.tabs {
}

.test {
  min-height: 34px;
  position: relative;
}

.tabs__buttons {
  position: absolute;
  display: flex;
  width: 100%;
  gap: 1px;
  overflow-y: auto;
  top: 1px;
}

.tabs__buttons > .button {
  border-radius: 0 !important;
  padding: 8px 12px;
  background-color: #f9f9f9;
  color: #494949;
  line-height: 16px;
  position: relative;
  border-color: #c5d0dc;
  border: 1px solid #cdcdcd;
  font-size: 13px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  white-space: pre;
  gap: 4px;
  border-bottom-color: transparent;
}

.tabs__buttons > .button:disabled {
  opacity: 0.3;
  cursor: default;
}

.tabs__buttons > .button.active {
  border-color: #c5d0dc;
  border-bottom-color: transparent;
  border-top: 2px solid #4c8fbd;
  color: #576373;
  background-color: #fff;
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.15);
  cursor: default;
  margin-top: -1px;
}

.tabs__buttons > .button:hover:not(.active) {
  background-color: #fff;
  color: #4c8fbd;
  border-color: #c5d0dc;
}

.tabs__content {
  border: 1px solid #c5d0dc;
  padding: 16px 12px;
  overflow: visible;
}

.tabs__content > section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
