.header-with-reactive-value-and-save-btn {
  display: flex;
  flex-direction: row nowrap;
  background: #fff;
  padding: 14px 0 9px;
  margin-left: 20px;
  margin-right: -10px;
  border-bottom: 1px dotted #c9c9c9;
  align-items: flex-start;
  justify-content: space-between;
}

.header-with-reactive-value-and-save-btn__values {
  display: flex;
  flex-direction: row wrap;
  margin: 0 8px;
  font-size: 14px;
  line-height: 1;
  font-weight: 300;
  margin-top: 8px;
  gap: 20px;
}

.header-with-reactive-value-and-save-btn__value-item {
  display: flex;
  flex-flow: row wrap;
}

.header-with-reactive-value-and-save-btn__value-item .label {
  margin: 0 6px;
  color: #8089a0;
}

.header-with-reactive-value-and-save-btn__value-item .value {
  color: #2679b5;
}

.header-with-reactive-value-and-save-btn__buttons {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.header-with-reactive-value-and-save-btn__btn {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
}
